// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-cgv-tsx": () => import("./../../../src/templates/cgv.tsx" /* webpackChunkName: "component---src-templates-cgv-tsx" */),
  "component---src-templates-legal-notice-tsx": () => import("./../../../src/templates/legal-notice.tsx" /* webpackChunkName: "component---src-templates-legal-notice-tsx" */),
  "component---src-templates-shipping-tsx": () => import("./../../../src/templates/shipping.tsx" /* webpackChunkName: "component---src-templates-shipping-tsx" */),
  "component---src-templates-shop-tsx": () => import("./../../../src/templates/shop.tsx" /* webpackChunkName: "component---src-templates-shop-tsx" */)
}


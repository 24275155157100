import * as React from "react";
import Wrapper from "../wrapper";
import { GatsbyBrowser, WrapPageElementBrowserArgs } from "gatsby";
import { GatsbyShop } from "../utils/shop";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props: {
    pageContext: { shop },
  },
}: WrapPageElementBrowserArgs<{}, { shop: GatsbyShop }>) => {
  return <Wrapper color={shop?.color}>{element}</Wrapper>;
};

import { createTheme as createMuiTheme } from "@mui/material";
import { frFR } from "@mui/x-date-pickers";

export const createTheme = (color?: string) =>
  createMuiTheme(
    {
      spacing: 10,
      breakpoints: {
        values: {
          xs: 0,
          sm: 576,
          md: 960,
          lg: 1024,
          xl: 1230,
        },
      },
      palette: {
        primary: {
          main: color || "#C52F30",
          contrastText: "#fff",
        },
        secondary: {
          main: "#393939",
        },
        info: {
          main: "#000000",
        },
        error: {
          main: "#ff0000",
        },
        background: {
          default: "#f5f5f5",
        },
        text: {
          primary: "#393939",
          secondary: color || "#C52F30",
        },
        common: {
          black: "#000",
          white: "#fff",
        },
      },
      typography: {
        h1: {
          fontSize: 32,
          fontWeight: "bold",
        },
        h2: {
          fontSize: 24,
          fontWeight: "bold",
        },
        h3: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
    },
    frFR
  );
